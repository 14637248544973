import React from 'react';
import styled from 'styled-components';
import { media } from '../../helpers';
import Box from '../../styled/Box';
import { IBoxProps } from '../../styled/Box/Box';
import { Label } from '../Inputs/TextInput/TextInput';

const FormWrapper = styled(Box)`
  flex: 1;

  ${Label} {
    margin-top: 1.5em;
  }

  > ${Label}:first-child {
    margin-top: 0;
  }

  min-width: 450px;
  ${media.phone`
    min-width: 100%;
  `}
  max-width: 450px;
  padding: 16px;
`;

interface IProps extends IBoxProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

const Form = ({ children, title, ...rest }: IProps) => (
  <FormWrapper {...rest}>
    { title && ( <Box bold center mB={8}>{ title }</Box> )}
    { children }
  </FormWrapper>
);

export default Form;
