import React from 'react';
import styled from 'styled-components';
import { media } from '../../helpers';

export interface IBoxProps {
  container?: boolean;
  grow?: boolean;
  basis?: number;
  zIndex?: number;
  shadow?: boolean | string;
  p?: number;
  pT?: number;
  pR?: number;
  pB?: number;
  pL?: number;
  pY?: number;
  pX?: number;
  background?: string;
  alignItems?: string;
  alignSelf?: string;
  border?: boolean | string;
  bold?: boolean;
  visible?: boolean;
  font?: number;
  width?: number | string;
  height?: number | string;
  flex?: boolean | number;
  row?: boolean;
  col?: boolean;
  justifyContent?: string;
  m?: number;
  mT?: number;
  mR?: number;
  mB?: number;
  mL?: number;
  mY?: number;
  mX?: number;
  color?: string;
  overflow?: boolean;
  maxHeight?: number;
  maxWidth?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  italic?: boolean;
  center?: boolean;
  colOnSm?: boolean;
  absolute?: boolean;
  relative?: boolean;
  right?: number;
  borderLeft?: string;
  borderRight?: string;
  uppercase?: boolean;
  icon?: boolean;
  hidden?: boolean;
}

const Box = styled.div<IBoxProps>`
  ${({ container, row, col, colOnSm }) => (container || row || col || colOnSm) && 'display: flex;'}
  ${({ flex }) => {
    if (!flex) { return ''; }
    if (typeof flex === 'number') {
      return `flex: ${flex};`;
    }
    if (typeof flex === 'boolean') {
      return 'display: flex';
    }
    return '';
  }}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  ${({ grow }) => grow && 'flex: 1;'}
  ${({ row }) => row && 'flex-direction: row;'}
  ${({ col }) => col && 'flex-direction: column;'}
  ${({ basis }) => basis && `
    flex-basis: ${basis}%;
    min-width: ${basis}%;
  `}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ shadow }) => {
    if (!shadow) { return null; }
    if (shadow === 'right') {
      return 'box-shadow: 0 3px 5px 0 hsla(0, 0%, 0%, 0.32);';
    }
    return 'box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, 0.32);';
  }}
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ pT }) => pT && `padding-top: ${pT}px;`}
  ${({ pR }) => pR && `padding-right: ${pR}px;`}
  ${({ pB }) => pB && `padding-bottom: ${pB}px;`}
  ${({ pL }) => pL && `padding-left: ${pL}px;`}
  ${({ pY }) => pY && `padding-top: ${pY}px; padding-bottom: ${pY}px;`}
  ${({ pX }) => pX && `padding-left: ${pX}px; padding-right: ${pX}px;`}
  ${({ m }) => m && `margin: ${m}px;`}
  ${({ mY }) => mY && `margin-top: ${mY}px; margin-bottom: ${mY}px;`}
  ${({ mX }) => mX && `margin-left: ${mX}px; margin-right: ${mX}px;`}
  ${({ mT }) => mT && `margin-top: ${mT}px;`}
  ${({ mR }) => mR && `margin-right: ${mR}px;`}
  ${({ mB }) => mB && `margin-bottom: ${mB}px;`}
  ${({ mL }) => mL && `margin-left: ${mL}px;`}
  ${({ background }) => background && `background-color: ${background};`}
  ${({ border }) => {
    if (!border) { return null; }
    if (typeof border === 'string') {
      return `border: ${border};`;
    }
    return 'border: 1px solid red;';
  }}
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft};`}
  ${({ borderRight }) => borderRight && `border-right: ${borderRight};`}
  ${({ bold }) => bold && 'font-weight: 600;'}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ center }) => center && 'text-align: center;'}
  ${({ font }) => font && `font-size: ${font}px;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ width }) => {
    if (!width) { return null; }
    if (typeof width === 'string') {
      return `width: ${width};`;
    }
    return `width: ${width}px;`;
  }}
  ${({ height }) => {
    if (!height) { return null; }
    if (typeof height === 'string') {
      return `height: ${height};`;
    }
    return `height: ${height}px;`;
  }}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ maxWidth }) => {
    if (!maxWidth) { return null; }
    if (typeof maxWidth === 'string') {
      return `max-width: ${maxWidth};`;
    }
    return `max-width: ${maxWidth}px;`;
  }}
  ${({ minWidth }) => {
    if (!minWidth) { return null; }
    if (typeof minWidth === 'string') {
      return `min-width: ${minWidth};`;
    }
    return `min-width: ${minWidth}px;`;
  }}
  ${({ minHeight }) => {
    if (!minHeight) { return null; }
    if (typeof minHeight === 'string') {
      return `min-height: ${minHeight};`;
    }
    return `min-height: ${minHeight}px;`;
  }}
  ${({ overflow }) => overflow && `overflow: auto; -webkit-overflow-scrolling: touch;`}
  ${({ colOnSm }) => colOnSm && media.phone`
    flex-direction: column;
  `}
  ${({ absolute }) => absolute && `position: absolute;`}
  ${({ relative }) => relative && `position: relative;`}
  ${({ right }) => right && `right: ${right}px;`}
  ${({ uppercase }) => uppercase && `text-transform: uppercase;`}
  ${({ icon }) => icon && `
    svg {
      width: 24px;
      height: 24px;
    }
  `}
  ${({ hidden }) => hidden && 'display: none;'}
`;

export default Box;
