import { GeoCollectionReference, GeoFirestoreTypes } from 'geofirestore';
import React from 'react';
import { Link } from 'react-router-dom';
import Popover from 'react-tiny-popover';
import styled from 'styled-components';
import candleSrc from '../../candle.png';
import { FirebaseContext } from '../../contexts/firebase/FirebaseProvider';
import firebase from '../../firebase';
import flareSrc from '../../flare.png';
import lighthouseSrc from '../../lighthouse.png';
import Box from '../../styled/Box';
import { Label } from '../Inputs/TextInput/TextInput';

interface IBeacon {
  coordinates: {
    latitude: number;
    longitude: number;
  };
  creator: string;
  endDate: {
    seconds: number;
  };
  type: 'candle' | 'flare' | 'lighthouse' | 'watchtower';
}

interface IProps {
  document: GeoFirestoreTypes.QueryDocumentSnapshot;
  collection: GeoCollectionReference;
}

const types: {
  [type: string]: string,
} = {
  candle: candleSrc,
  flare: flareSrc,
  lighthouse: lighthouseSrc,
};

const IconBase = styled.img`
  width: 64px;
  height: 64px;
`;

const useTimerUpdate = (endTime: number, inputs: any[]) => {
  const [timeRemaining, setTimeRemaining] = React.useState(endTime - (+new Date() / 1000));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(endTime - (+new Date() / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, inputs);

  const totalSeconds = Math.max(0, timeRemaining);
  const minutes = (Math.floor(totalSeconds / 60)).toFixed().padStart(2, '0');
  const seconds = (totalSeconds % 60).toFixed().padStart(2, '0');
  const display = totalSeconds > 0 ? `${minutes}:${seconds}` : 'Expired';

  return {
    display,
    totalSeconds,
  };
};

const Beacon = (props: IProps) => {
  const { document, collection } = props;
  const { user } = React.useContext(FirebaseContext);
  const [open, setOpen] = React.useState(false);
  const data = document.data() as IBeacon;
  const isOwner = data.creator === user!.uid;
  const iconSrc = types[data.type] || types.candle;
  const beaconCheckinRef = firebase.firestore().doc(`beacon_checkins/${document.id}`);
  const userCheckinRef = firebase.firestore().doc(`user_checkins/${user.uid}`);
  const [userCheckins, setUserCheckins] = React.useState({});

  const deleteDocument = () => {
    if (isOwner) {
      collection.doc(document.id).delete();
      setOpen(false);
    }
  };

  const timeRemaining = useTimerUpdate(data.endDate.seconds, [data.endDate.seconds]);

  React.useEffect(() => {
    return beaconCheckinRef.onSnapshot((value) => {
      const checkins = Object.entries(value.data() || {}).reduce((acc, [uid, hasCheckedIn]) => {
        // Filter down checkins to which ones are truthy
        if (hasCheckedIn) {
          acc[uid] = hasCheckedIn;
        }
        return acc;
      }, {} as any);
      setUserCheckins(checkins);
    });
  }, []);

  if (timeRemaining.totalSeconds <= 0) {
    return null;
  }

  const handleClick = async () => {
    const hasCheckedIn = userCheckins && (userCheckins as any)[user.uid];

    if (hasCheckedIn) {
      console.log('You\'ve already checked in for this beacon.');
    } else {
      console.log('You\'ve checked in for this beacon.');
    }

    beaconCheckinRef.set({
      [user.uid]: !hasCheckedIn,
    }, {
      merge: true,
    });

    userCheckinRef.set({
      [document.id]: !hasCheckedIn,
    }, {
      merge: true,
    });
  };

  return (
    <div className="bg-white shadow-md p-4 mb-4 flex max-w-md w-full relative" onClick={handleClick}>
      <Box mR={16}>
        <IconBase src={iconSrc} />
      </Box>
      <Box>
        <Box>
          <Label>Distance</Label>
          <strong>{(document.distance * 1000 * 3.3).toFixed(2)}</strong> feet away
        </Box>
        <Box>
          <Label>Checkins</Label>
          <strong>{Object.keys(userCheckins).length}</strong>
        </Box>
        <Box>
          <Label>Time Remaining</Label>
          <strong>{timeRemaining.display}</strong>
        </Box>
        {isOwner && (
            <div className="absolute pin-t pin-r mt-4 mr-4 cursor-pointer">
              <Popover
                isOpen={open}
                position={'bottom'}
                transitionDuration={0.1}
                onClickOutside={() => setOpen(false)}
                content={(
                  <div className="bg-white border rounded-lg flex flex-col">
                    <Link to="#" className="py-2 px-4 hover:bg-grey-light no-underline text-black border-b">Edit</Link>
                    <Link to="#" className="py-2 px-4 hover:bg-grey-light no-underline text-black border-b">Export</Link>
                    <div className="py-2 px-4 hover:bg-grey-light no-underline text-black cursor-pointer" onClick={deleteDocument}>Delete</div>
                  </div>
                )}
              >
                <div onClick={() => setOpen((isOpen) => !isOpen)}>
                  <svg x="0px" y="0px" viewBox="0 0 512 512">
                    <g>
                      <circle cx="256" cy="256" r="64" />
                      <circle cx="256" cy="448" r="64" />
                      <circle cx="256" cy="64" r="64" />
                    </g>
                  </svg>
                </div>
              </Popover>
            </div>
          )
        }
      </Box>
    </div>
  );
};

export default Beacon;
