import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import firebase from '../../../firebase';
import headerSrc from '../../../header.png';
import OutlinedButton from '../../../styled/Buttons/OutlinedButton';
import TextInput from '../../Inputs/TextInput';

interface IAuthError {
  code: string;
  message: string;
}

const getErrorMessage = (error: IAuthError) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Please double check your email.';
    case 'auth/wrong-password':
      return 'Please double check your password.';
    default:
      return error.message;
  }
};

interface IProps extends RouteComponentProps<{}> {}

const SendPasswordForm: React.SFC<IProps> = (props) => {
  const defaultEmail = localStorage.getItem('emailForSignIn') || localStorage.getItem('email') || '';
  const [error, setError] = React.useState<IAuthError>({} as IAuthError);
  const [email, setEmail] = React.useState<string>(defaultEmail);
  const [hasSentEmail, setHasSentEmail] = React.useState(false);
  const emailHasError = ['auth/user-not-found', 'auth/invalid-email'].includes(error.code);

  const login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      e.preventDefault();
      await firebase.auth().sendSignInLinkToEmail(email, {
        handleCodeInApp: true,
        url: 'https://fb.mistersatuga.com/send-password-confirm',
      });
      localStorage.setItem('emailForSignIn', email);
      setHasSentEmail(true);
      setEmail('');
    } catch (authError) {
      setError(authError as IAuthError);
      console.error(authError);
    }
  };

  return (
    <div className="flex justify-center items-center mx-auto h-full flex-col">
      <img src={headerSrc} className="max-w-xs" />
      <div className="flex flex-col md:flex-row w-full justify-center items-center md:flex-row-reverse ">
        <form className="bg-white shadow-md rounded border-t-4 border-blue-dark max-w-xs w-full mt-4">
          <div className="px-6 pt-6 pb-8">
            { hasSentEmail && (
              <div className="bg-blue-lightest border border-blue-light text-blue-dark px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">
                  Please check your email for a sign in link.
                </span>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Email
              </label>
              <TextInput
                value={email}
                onChange={setEmail}
                placeholder="dab@gmail.com"
                error={emailHasError ? getErrorMessage(error) : ''}
              />
            </div>
            <div className="flex items-center justify-between">
              <button className="
                bg-blue
                focus:outline-none
                focus:shadow-outline
                font-bold
                hover:bg-blue-dark
                px-4
                py-2
                rounded
                text-white
                w-full
                "
                type="submit"
                onClick={login}
              >
                Send Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendPasswordForm;
