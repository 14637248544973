
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyCBGouxcFbTyJc_k4t48Tl3Q4xfwB7kr1I',
  authDomain: 'drop-a-beacon.firebaseapp.com',
  databaseURL: 'https://drop-a-beacon.firebaseio.com',
  messagingSenderId: '975121716821',
  projectId: 'drop-a-beacon',
  storageBucket: 'drop-a-beacon.appspot.com',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;
