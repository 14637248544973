import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import firebase from '../../../firebase';
import headerSrc from '../../../header.png';
import OutlinedButton from '../../../styled/Buttons/OutlinedButton';
import TextInput from '../../Inputs/TextInput';

interface IAuthError {
  code: string;
  message: string;
}

const getErrorMessage = (error: IAuthError) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Please double check your email.';
    case 'auth/wrong-password':
      return 'Please double check your password.';
    case 'auth/user-disabled':
      return 'This account has been disabled.';
    default:
      return error.message;
  }
};

interface IProps extends RouteComponentProps<{}> {}

const LoginForm: React.SFC<IProps> = (props) => {
  const [error, setError] = React.useState<IAuthError>({} as IAuthError);
  const [email, setEmail] = React.useState<string>(localStorage.getItem('email') || '');
  const [password, setPassword] = React.useState<string>('');
  const emailHasError = ['auth/user-not-found', 'auth/invalid-email', 'auth/user-disabled'].includes(error.code);
  const passwordHasError = ['auth/wrong-password'].includes(error.code);

  const login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      e.preventDefault();
      await firebase.auth().signInWithEmailAndPassword(email, password);
      localStorage.setItem('email', email);
      props.history.push('/');
    } catch (authError) {
      setError(authError as IAuthError);
    }
  };

  return (
    <div className="flex justify-center items-center mx-auto h-full flex-col">
      <img src={headerSrc} className="max-w-xs" />
      <div className="flex flex-col md:flex-row w-full justify-center items-center md:flex-row-reverse ">
        <form className="bg-white shadow-md rounded border-t-4 border-blue-dark max-w-xs w-full mt-4">
          <div className="px-6 pt-6 pb-8">
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Email
              </label>
              <TextInput
                value={email}
                onChange={setEmail}
                placeholder="dab@gmail.com"
                error={emailHasError ? getErrorMessage(error) : ''}
              />
            </div>
            <div className="mb-6">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Password
              </label>
              <TextInput
                value={password}
                onChange={setPassword}
                id="password"
                type="password"
                placeholder="******************"
                error={passwordHasError ? getErrorMessage(error) : ''}
              />
            </div>
            <div className="flex items-center justify-between">
              <button className="
                bg-blue
                focus:outline-none
                focus:shadow-outline
                font-bold
                hover:bg-blue-dark
                px-4
                py-2
                rounded
                text-white
                w-full
                "
                type="submit"
                onClick={login}
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="w-full bg-grey-lighter text-sm p-6 flex justify-around">
            <div>Don't have an account?</div>
            <Link
              to="/register"
              className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
            >
              Register
            </Link>
          </div>
        </form>
        <div className="bg-white shadow-md rounded border-t-4 border-grey-darker max-w-xs w-full mt-4 mr-0 md:mr-4">
          <div className="px-6 pt-6 pb-8">
            <div className="font-bold text-center">
              In a rush?
            </div>
            <div className="mt-4 flex justify-center">
              <OutlinedButton onClick={async () => {
                const resp = await firebase.auth().signInAnonymously();
                props.history.push('/');
              }}>
              Continue as a Guest
              </OutlinedButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
