import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import firebase from '../../../firebase';
import headerSrc from '../../../header.png';
import OutlinedButton from '../../../styled/Buttons/OutlinedButton';
import TextInput from '../../Inputs/TextInput';

interface IAuthError {
  code: string;
  message: string;
}

const getErrorMessage = (error: IAuthError) => {
  switch (error.code) {
    case 'auth/invalid-action-code':
      return 'This link has expired. Please try again.';
    default:
      return error.message;
  }
};

interface IProps extends RouteComponentProps<{}> {}

const ConfirmEmailForm: React.SFC<IProps> = (props) => {
  const defaultEmail = localStorage.getItem('emailForSignIn') || localStorage.getItem('email') || '';
  const [error, setError] = React.useState<IAuthError>({} as IAuthError);
  const [email, setEmail] = React.useState<string>(defaultEmail);
  const [promptForEmail, setPromptForEmail] = React.useState(false);
  const emailHasError = ['auth/user-not-found', 'auth/invalid-email'].includes(error.code);

  const doSignIn = (emailAddress: string) => {
    firebase.auth().signInWithEmailLink(emailAddress, window.location.href)
      .then(() => {
        localStorage.removeItem('emailForSignIn');
        props.history.push('/');
      })
      .catch((authError: IAuthError) => {
        setError(authError);
        console.error(authError);
      });
  };

  React.useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const storedEmail = localStorage.getItem('emailForSignIn');

      if (storedEmail) {
        doSignIn(storedEmail);
      } else {
        setPromptForEmail(true);
      }
    }
  }, []);

  const verify = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    doSignIn(email);
  };

  return (
    <div className="flex justify-center items-center mx-auto h-full flex-col">
      <img src={headerSrc} className="max-w-xs" />
      <div className="flex flex-col md:flex-row w-full justify-center items-center md:flex-row-reverse ">
        <form className="bg-white shadow-md rounded border-t-4 border-blue-dark max-w-xs w-full mt-4">
          <div className="px-6 pt-6 pb-8">
            {
              error.code && (
                <div className="bg-orange-lightest border border-orange-light text-orange-dark px-4 py-3 rounded" role="alert">
                  <span className="block sm:inline">
                    { getErrorMessage(error) }
                  </span>
                </div>
              )
            }
            {
              promptForEmail && (
                <>
                  <div className="bg-blue-lightest border border-blue-light text-blue-dark px-4 py-3 rounded mb-4" role="alert">
                    <span className="block sm:inline">
                      Please verify your email address.
                    </span>
                  </div>
                  <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2">
                      Email
                    </label>
                    <TextInput
                      value={email}
                      onChange={setEmail}
                      placeholder="dab@gmail.com"
                      error={emailHasError ? getErrorMessage(error) : ''}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <button className="
                      bg-blue
                      focus:outline-none
                      focus:shadow-outline
                      font-bold
                      hover:bg-blue-dark
                      px-4
                      py-2
                      rounded
                      text-white
                      w-full
                      "
                      type="submit"
                      onClick={verify}
                    >
                      Verify Email
                    </button>
                  </div>
                </>
              )
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmEmailForm;
