import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Box from '../../../styled/Box';
import './TextInput.css';

const Input = styled.input.attrs({
  type: 'text',
})`
  border-radius: 4px;
  border: 0;
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);
  background-color: hsla(0, 0%, 0%, 0.03);
  width: 100%;
  padding: 8px 12px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.50);
    opacity: 1;
  }
`;

interface IProps extends React.HTMLProps<HTMLInputElement> {
  value: any;
  type?: 'text' | 'email' | 'password';
  error?: string;
  onChange: (value: any) => void;
}

const TextInput = (props: IProps) => {
  const {
    error,
    onChange,
    ...rest } = props;

  return (
    <React.Fragment>
      <input
        className={
          classNames([
            'appearance-none',
            'border',
            'focus:outline-none',
            'focus:shadow-outline',
            'leading-tight',
            'px-3',
            'py-2',
            'rounded',
            'shadow',
            'text-grey-darker',
            'w-full',
            {
              'border-red': error,
            },
          ])}
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        {...rest}
      />
      { !!error && (
        <p className="text-red text-xs italic mt-4">{error}</p>
      )}
    </React.Fragment>
  );
};

export default TextInput;

export const Label = styled(Box)`
  font-size: 12px;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: bold;
  color: hsla(0,0%,30%,1);
`;
