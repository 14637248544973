import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect, Route, Switch } from 'react-router';
import ConfirmEmailForm from '../components/Forms/ConfirmEmailForm';
import LoginForm from '../components/Forms/LoginForm';
import RegisterForm from '../components/Forms/RegisterForm';
import SendPasswordForm from '../components/Forms/SendPasswordForm';
import { FirebaseContext } from '../contexts/firebase/FirebaseProvider';
import firebase from '../firebase';
import HomePage from '../pages/HomePage';

const AuthRoute = ({ component: Component, ...rest }: any) => {
  const { auth } = React.useContext(FirebaseContext);
  const { initialising, user } = useAuthState(firebase.auth());

  return (
    <Route {...rest} render={(props) => {
      if (!user) {
        console.log('user is not logged in', initialising);
        if (!initialising) {
          return <Redirect to="/login" />;
        }
        return null;
      }

      return <Component {...props} />;
    }} />
  );
};

const Routes = () => {
  return (
    <Switch>
      <AuthRoute exact path="/" component={HomePage} />
      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/register" component={RegisterForm} />
      <Route exact path="/send-password" component={SendPasswordForm} />
      <Route exact path="/send-password-confirm" component={ConfirmEmailForm} />
    </Switch>
  );
};

export default Routes;
