import { css } from 'styled-components';

export interface ISizes {
  [key: string]: number;
  large: number;
  medium: number;
  small: number;
  phone: number;
  desktop: number;
}

export const sizes: ISizes = {
  desktop: 1200,
  large: 1200,
  medium: 992,
  phone: 1024,
  small: 768,
};

export interface IMedia {
  desktop: (...styled: any[]) => string;
  large: (...styled: any[]) => string;
  medium: (...styled: any[]) => string;
  phone: (...styled: any[]) => string;
  small: (...styled: any[]) => string;
  tablet: (...styled: any[]) => string;
}

const defaultMedia = {
  tablet: (arg: any, ...args: any[]) => css`
    @media only screen
      and (min-width: 1366px)
      and (max-width: 1366px) {
      ${css(arg, ...args)}
    }
    @media only screen
      and (min-width: 1024px)
      and (max-width: 1024px) {
      ${css(arg, ...args)}
    }
  `,
};

const media: IMedia = Object.keys(sizes).reduce((acc: any, label) => {
  const minOrMax = label === 'desktop' ? 'min' : 'max';

  acc[label] = (arg: any, ...args: any[]) => css`
    @media (${minOrMax}-width: ${sizes[label]}px) {
      ${css(arg, ...args)}
    }
  `;
  return acc;
}, defaultMedia);

export default media;
