import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import firebase from '../../firebase';
import OutlinedButton from '../../styled/Buttons/OutlinedButton';

interface IProps extends RouteComponentProps<{}> {

}

const handleLogout = (props: IProps) => {
  firebase.auth().signOut();
  props.history.push('/login');
};

const Header = (props: IProps) => (
  <nav className="flex items-center justify-between flex-wrap bg-blue p-4 mb-4 sticky pin-t z-10">
    <div className="flex items-center flex-no-shrink text-white flex-1 justify-between">
      <div className="flex items-center">
        <svg className="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" /></svg>
        <span className="font-semibold text-xl tracking-tight">Drop a Beacon</span>
      </div>
      <div>
        <OutlinedButton
          onClick={() => handleLogout(props)}
          className="text-black"
        >
          Log Out
        </OutlinedButton>
      </div>
    </div>
  </nav>
);

export default withRouter(Header);
