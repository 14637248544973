import { GeoCollectionReference, GeoFirestore, GeoFirestoreTypes, GeoQuery, GeoQuerySnapshot } from 'geofirestore';
import React from 'react';
import { Spring, Transition, animated } from 'react-spring';
import { FirebaseContext } from '../../contexts/firebase/FirebaseProvider';
import firebase from '../../firebase';
import Box from '../../styled/Box';
import OutlinedButton from '../../styled/Buttons/OutlinedButton';
import Beacon from '../Beacon';
import Form from '../Form';
import TextInput from '../Inputs/TextInput';
import { Label } from '../Inputs/TextInput/TextInput';

const firestore = firebase.firestore();
const geofirestore: GeoFirestore = new GeoFirestore(firestore);

const useWatchPosition = () => {
  const [position, setPosition] = React.useState<any>({
    coords: { latitude: 0, longitude: 0 },
  });

  React.useEffect(() => {
    const watchID = navigator.geolocation.watchPosition(setPosition, () => ({}), { enableHighAccuracy: true });
    return () => navigator.geolocation.clearWatch(watchID);
  }, []);

  return position;
};

const BeaconCollection = () => {
  const { user } = React.useContext(FirebaseContext);
  const geocollection: GeoCollectionReference = geofirestore.collection(`beacons`);
  const [documents, setDocuments] = React.useState<GeoFirestoreTypes.QueryDocumentSnapshot[]>([]);
  const position = useWatchPosition();
  if (!position) {
    return <p>Waiting for your location... please make sure your location services are enabled.</p>;
  }
  const [distance, setDistance] = React.useState<number>(10000);
  const latitude = Number(position ? position.coords.latitude : 0);
  const longitude = Number(position ? position.coords.longitude : 0);

  React.useEffect(() => {
    // Converts from feet to kilometers
    const radius = (distance / 1000 / 3.3) || 0.001;

    const query: GeoQuery = geocollection.near({
      center: new firebase.firestore.GeoPoint(latitude, longitude),
      radius,
    });

    return query.onSnapshot((snapshot: GeoQuerySnapshot) => {
      setDocuments(snapshot.docs.filter((doc) => {
        // Only show active beacons.
        return doc.data().endDate && doc.data().endDate.seconds <= +new Date();
      }));
    });
  }, [latitude, longitude, distance]);

  const onDropBeacon = () => {
    const types = ['candle', 'flare', 'lighthouse'];
    const type = types[Math.floor(Math.random() * types.length)];

    const randomAmount = 0 * (Math.floor(Math.random() * 10) / 3600);

    const data = {
      coordinates: {
        latitude: position.coords.latitude + randomAmount,
        longitude: position.coords.longitude + randomAmount,
      },
      creator: user!.uid,
      endDate: new Date(new Date().getTime() + 30 * 60000), // 30 minutes from now
      type,
    };

    geocollection.doc().set(data);
  };

  const beacons = documents.filter((doc) => doc.data().endDate.seconds >= (+new Date() / 1000));

  return (
    <Box>
      <Form title="Search Details" background="white" shadow mB={16}>
        <Box>
          <Label>Radius (feet)</Label>
          <TextInput
            value={distance}
            onChange={setDistance}
          />
        </Box>
        <Box flex justifyContent="center" mT={16}>
          <OutlinedButton onClick={onDropBeacon} border="hsl(209, 28%, 39%)">
            Drop a Beacon
          </OutlinedButton>
        </Box>
      </Form>
      <Box>
        There are {beacons.length} beacons within {distance} feet of you.
      </Box>
      <Box m={16}>
        <Transition
          items={beacons}
          keys={beacons.map((beacon) => beacon.id)}
          from={{ opacity: 0, height: 0 }}
          enter={{
            height: 'auto',
            opacity: 1,
          }}
          leave={{ opacity: 0, height: 0 }}
        >
          {(item) => (styles) => (
            <animated.div style={styles} key={item.id}>
              <Beacon document={item} collection={geocollection} />
            </animated.div>
          )}
        </Transition>
      </Box>
    </Box>
  );
};

export default BeaconCollection;
