import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import firebase from '../../../firebase';
import headerSrc from '../../../header.png';
import TextInput from '../../Inputs/TextInput';

interface IAuthError {
  code: string;
  message: string;
}

const getErrorMessage = (error: IAuthError) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'This email address has already been registered.';
    case 'auth/weak-password':
      return 'Please use a stronger password.';
    default:
      return error.message;
  }
};

type IProps = RouteComponentProps<{}>;

const RegisterForm = (props: IProps) => {
  const [error, setError] = React.useState<IAuthError>({} as IAuthError);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const emailHasError = ['auth/email-already-in-use'].includes(error.code);
  const passwordHasError = ['auth/weak-password'].includes(error.code);

  const register = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      e.preventDefault();
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      localStorage.setItem('email', email);
      props.history.push('/');
    } catch (authError) {
      setError(authError as IAuthError);
      console.error(authError);
    }
  };

  return (
    <div className="flex justify-center items-center mx-auto h-full flex-col">
      <img src={headerSrc} className="max-w-xs" />
      <form className="bg-white shadow-md rounded border-t-4 border-blue-dark max-w-xs w-full mt-4">
        <div className="px-6 pt-6 pb-8">
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              Email
            </label>
            <TextInput
              value={email}
              onChange={setEmail}
              placeholder="dab@gmail.com"
              error={emailHasError ? getErrorMessage(error) : ''}
            />
          </div>
          <div className="mb-6">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              Password
            </label>
            <TextInput
              value={password}
              onChange={setPassword}
              id="password"
              type="password"
              placeholder="******************"
              error={passwordHasError ? getErrorMessage(error) : ''}
            />
          </div>
          <div className="mb-6">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              Confirm Password
            </label>
            <TextInput
              value={confirmPassword}
              onChange={setConfirmPassword}
              id="confirmPassword"
              type="password"
              placeholder="******************"
              error={(confirmPassword && password !== confirmPassword) ? 'Passwords do not match' : ''}
            />
          </div>
          <div className="flex items-center justify-between">
            <button className="
              bg-blue
              focus:outline-none
              focus:shadow-outline
              font-bold
              hover:bg-blue-dark
              px-4
              py-2
              rounded
              text-white
              w-full
              "
              type="submit"
              onClick={register}
            >
              Register
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
