import React from 'react';
import BeaconCollection from '../../components/BeaconCollection';
import Header from '../../components/Header';

const HomePage = () => {
  return (
    <>
      <Header />
      <div className="flex justify-center flex-col items-center max-w-xs mx-auto">
        <BeaconCollection />
      </div>
    </>
  );
};

export default HomePage;
