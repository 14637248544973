import React from 'react';
import styled from 'styled-components';
import Box from '../../Box';

interface IBase {
  background?: string;
  border?: string;
  disabled?: boolean;
}

const Base = styled(Box)`
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ border }: IBase) => border};
  background-color: ${({ background }: IBase) => background};
  box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, 0.15);
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px 4px 6px;
  text-transform: uppercase;

  :hover {
    transform: translateY(-1px);
  }

  .text {
    letter-spacing: 0.8px;
    margin-left: 8px;
  }

  * > .primary {
    fill: #0c82ba;
  }

  * > .secondary {
    fill: rgba(77, 77, 77, 0.85);
  }

  ${({ disabled }: IBase) => disabled && `
    cursor: auto;
    background-color: #CACACA;
    border: 1px solid #a9a9a9;
    :hover {
      transform: none;
    }
  `}
`;

interface IProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  border?: string;
  background?: string;
  disabled?: boolean;
  className?: string;
}

const OutlinedButton = (props: IProps) => {
  const { onClick, icon, children, ...rest } = props;

  return (
    <Base onClick={props.disabled ? () => ({}) : onClick} {...rest}>
      { icon }
      <Box className="text">{ children }</Box>
    </Base>
  );
};

OutlinedButton.defaultProps = {
  background: 'white',
  border: '#CACACA',
};

export default OutlinedButton;
