/* eslint react/no-unused-state: 0 */
/* eslint react/destructuring-assignment: 0 */
/* tslint:disable:object-literal-sort-keys */
import React from 'react';
import firebase from '../../firebase';

export interface IFirebaseContext {
  auth: firebase.auth.Auth;
  user: firebase.User;
}

export const FirebaseContext = React.createContext({} as IFirebaseContext);

class FirebaseProvider extends React.Component<{}, IFirebaseContext> {
  state = {
    auth: firebase.auth(),
    user: {} as firebase.User,
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState(() => ({
        user: user as firebase.User,
      }));
    });
  }

  render() {
    const { children } = this.props;

    return (
      <FirebaseContext.Provider value={this.state as IFirebaseContext}>
        {children}
      </FirebaseContext.Provider>
    );
  }
}

export default FirebaseProvider;
